import { ElMessage } from 'element-plus'
import 'element-plus/theme-chalk/el-message.css'
import '../style/toaster.scss'
import { getPromise } from '@shared/util/promise'

export const genToaster = (type, customClass = 'qs-toaster', options) => {
  return (message, showClose, opt = options) =>
    ElMessage(
      Object.assign(
        { showClose, message, type, customClass },
        opt,
      ),
    )
}

export const $success = genToaster('success')
export const $error = genToaster('error')
export const $info = genToaster('info')

export const $delayToast = (message, opts = {}) => {
  const { promise, resolve } = getPromise()
  const options = Object.assign({
    delay: 3000,
    instance: $info,
    unitGap: 1000,
  }, opts)
  let timer = null
  let initTime = options.delay
  const getTimeHtml = () => {
    return `<span id="time">${initTime / options.unitGap}s</span>`
  }
  const toastFn = options.instance(message, false, {
    duration: 0,
    dangerouslyUseHTMLString: true,
    message: `<div class="delay-toast-wrapper">${getTimeHtml()}${message}</div>`,
  })
  const updateMessage = () => {
    const delayToastWrapper = document.querySelector('.delay-toast-wrapper')
    const timeDom = delayToastWrapper.querySelector('#time')
    timeDom.remove()
    delayToastWrapper.insertAdjacentHTML('afterbegin', getTimeHtml())
  }
  timer = setInterval(() => {
    initTime -= options.unitGap
    if (initTime <= 0) {
      toastFn.close()
      clearInterval(timer)
      resolve()
    } else {
      updateMessage()
    }
  }, options.unitGap)
  return promise
}
